'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _window$popmotion = window.popmotion,
    easing = _window$popmotion.easing,
    physics = _window$popmotion.physics,
    spring = _window$popmotion.spring,
    tween = _window$popmotion.tween,
    styler = _window$popmotion.styler,
    listen = _window$popmotion.listen,
    value = _window$popmotion.value,
    transform = _window$popmotion.transform;
// var pipe = transform.pipe,
//     clampMax = transform.clampMax;

var Ball = function () {
    function Ball(e) {
        var _this = this;

        _classCallCheck(this, Ball);

        this.ball = e;
        this.ballStyler = styler(this.ball);

        this.count = 0;
        this.isFalling = false;

        this.ballY = value(0, function (v) {
            return _this.ballStyler.set('y', Math.min(0, v));
        });
        this.ballScale = value(1, function (v) {
            _this.ballStyler.set('scaleX', 1 + (1 - v));
            _this.ballStyler.set('scaleY', v);
        });

        this.gravity = physics({
            acceleration: 2500,
            restSpeed: false
        }).start(function (v) {
            _this.ballY.update(v);
            _this.checkBounce(v);
            _this.checkFail(v);
        });
    }

    _createClass(Ball, [{
        key: 'checkBounce',
        value: function checkBounce() {
            var _this2 = this;

            if (!this.isFalling || this.ballY.get() < 0) return;

            this.isFalling = false;

            var impactVelocity = this.ballY.getVelocity();

            var compression = spring({
                to: 1,
                from: 1,
                velocity: -impactVelocity * 0.01,
                stiffness: 800
            }).pipe(function (s) {
                if (s >= 1) {
                    s = 1;
                    compression.stop();

                    if (impactVelocity > 20) {
                        _this2.isFalling = true;
                        _this2.gravity.set(0).setVelocity(-impactVelocity * 0.5);
                    }
                }
                return s;
            }).start(this.ballScale);
        }
    }, {
        key: 'checkFail',
        value: function checkFail() {
            if (this.ballY.get() >= 0 && this.ballY.getVelocity() !== 0 && this.ball.innerHTML !== 'Tap') {
                this.count = 0;
            }
        }
    }]);

    return Ball;
}();

var balls = document.getElementsByClassName('pop');

Array.from(balls).forEach(function (b) {



    var ball = new Ball(b);

    listen(ball.ball, 'mousedown touchstart').start(function (e) {

        if(window.outerWidth <= 767){
            return;
        }

        e.preventDefault();
        ball.count++;
        // ball.innerHTML = count;

        ball.isFalling = true;
        ball.ballScale.stop();
        ball.ballScale.update(1);

        ball.gravity.set(Math.min(0, ball.ballY.get())).setVelocity(-1200);
    });
});